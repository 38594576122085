import React from 'react';
import { Link } from 'gatsby';

export default ({ tags }) => (
  <nav className={'breadcrumbs'}>
    <ol className={'breadcrumb'}>
      {tags
        .sort((a, b) => {
          return ('' + a).localeCompare(b);
        })
        .map(tag => (
          <li key={`/tag/${tag}`} className={'breadcrumb-item'}>
            <Link
              className={'text-gold'}
              to={`/tag/${tag.toLowerCase().replace(/\s+/g, '-')}`}
            >
              {tag}
            </Link>
          </li>
        ))}
    </ol>
  </nav>
);
