import Headroom from 'react-headroom/dist';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import React from 'react';
import { Link, withPrefix } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Tags from './Tags';

import '../styles/index.scss';

import SwipeableViews from 'react-swipeable-views';

const SwipeBack = ({ children }) => (
  <SwipeableViews
    index={1}
    disabled={
      typeof window !== 'undefined'
        ? Boolean(window.history.length) && false
        : true
    }
    onChangeIndex={index =>
      index === 0 && typeof window !== 'undefined'
        ? window.history.back()
        : null
    }
  >
    <div
      className={'bg-blue'}
      style={{
        width: '100vw',
        height: '100vh',
      }}
    />
    <>{children}</>
  </SwipeableViews>
);

const Layout = ({
  breadcrumbs,
  children,
  location,
  metadata,
  subjects,
  title,
}) => {
  const node = children.length ? children[0].props.data : children.props.data;
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          file(relativePath: { eq: "logo.png" }) {
            publicURL
            childImageSharp {
              fixed(width: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={logo => (
        <>
          <Helmet
            title={
              title
                ? title
                : node.title
                ? `${node.title} – ${metadata.title}`
                : metadata.title
            }
          />
          {location && location.pathname !== withPrefix('/') ? (
            <>
              <Headroom className="bg-blue">
                <Link to="/" title={metadata.title}>
                  <Img resolutions={logo.file.childImageSharp.fixed} />
                </Link>
              </Headroom>
              <SwipeBack>
                {children}
                {breadcrumbs ? <Tags tags={subjects} /> : null}
              </SwipeBack>
            </>
          ) : (
            <>
              {children}
              {breadcrumbs ? <Tags tags={subjects} /> : null}
            </>
          )}
        </>
      )}
    />
  );
};

export default Layout;
